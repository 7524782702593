import React from 'react'

import { Link } from 'docz'
import { TopNavigationItem } from '@entur/menu'
import { useCurrentSection } from '~/components/CurrentSectionProvider/CurrentSectionProvider'

import EnturLogo from '../Logo/EnturLogo'

import './NavBar.scss'
type NavItemProps = {
    to: string
    children: React.ReactNode
    [key: string]: any
}

const NavItem: React.FC<NavItemProps> = (props) => {
    const { currentSection } = useCurrentSection()

    return (
        <TopNavigationItem
            className="nav-bar__links__link"
            as={Link}
            to={props.to}
            active={currentSection == props.children}
        >
            {props.children}
        </TopNavigationItem>
    )
}

export default function NavBar(): JSX.Element {
    return (
        <div className="nav-bar">
            <div className="nav-bar__logo">
                <Link to="/">
                    <EnturLogo variant="blue" />
                </Link>
            </div>

            <nav className="nav-bar__links">
                <NavItem
                    className="nav-bar__links__link"
                    as={Link}
                    to="/pages-intro-overview"
                >
                    Overview
                </NavItem>
                <NavItem
                    className="nav-bar__links__link"
                    as={Link}
                    to="/stops-and-timetable-data"
                >
                    Open Data
                </NavItem>
                <NavItem
                    className="nav-bar__links__link"
                    as={Link}
                    to="/pages-geocoder-intro"
                >
                    Open Services
                </NavItem>
                <NavItem
                    className="nav-bar__links__link"
                    as={Link}
                    to="/pages-sales-intro"
                >
                    Partner Services
                </NavItem>
                <NavItem
                    className="nav-bar__links__link"
                    as={Link}
                    to="/pages-electronic-journal-intro"
                >
                    Internal
                </NavItem>
            </nav>
        </div>
    )
}
