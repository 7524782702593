import React from 'react'
import { Link as DoczLink } from 'docz'

import EnturLogo from '../Logo/EnturLogo'
import { Heading3, SubParagraph, Link } from '@entur/typography'
import Feedback from '~/components/Feedback/Feedback'

import './FrontPageFooter.scss'

export default function Index(): JSX.Element {
    return (
        <div className="frontpage-footer">
            <div className="frontpage-footer__logo">
                <DoczLink to="/">
                    <EnturLogo variant="blue" />
                </DoczLink>
            </div>
            <div className="frontpage-footer__address">
                <Heading3>Entur AS</Heading3>
                <SubParagraph>
                    Rådhusgata 5, 0151 Oslo
                    <br />
                    Postboks 1554 Vika, 0117
                    <br />
                    <br />
                    <Link href="mailto:post@entur.org">post@entur.org</Link>
                </SubParagraph>
            </div>
            <div className="frontpage-footer__feedback">
                <Heading3>Usability</Heading3>
                <SubParagraph as="div">
                    <Link href="https://uustatus.no/nb/erklaringer/publisert/a0b3f02b-b2ce-4611-bf7c-a0fc2d52620c">
                        Accessibility statement
                    </Link>
                    <br />
                    <br />
                    We appreciate any feedback you
                    <br />
                    might have about Entur Developer.
                    <br />
                    <Feedback isFooterFeedbackLink={true} />
                </SubParagraph>
            </div>
            <div className="frontpage-footer__pages">
                <Heading3>Pages</Heading3>
                <SubParagraph>
                    <DoczLinkWrapper to="/pages-intro-overview">
                        Overview
                    </DoczLinkWrapper>
                    <br />
                    <DoczLinkWrapper to="/stops-and-timetable-data">
                        Open Data
                    </DoczLinkWrapper>
                    <br />
                    <DoczLinkWrapper to="/pages-geocoder-intro">
                        Open Services
                    </DoczLinkWrapper>
                    <br />
                    <DoczLinkWrapper to="/pages-sales-intro">
                        Partner Services
                    </DoczLinkWrapper>
                    <br />
                    <DoczLinkWrapper to="/pages-electronic-journal-intro">
                        Internal
                    </DoczLinkWrapper>
                    <br />
                </SubParagraph>
            </div>
        </div>
    )
}

function DoczLinkWrapper(props: any) {
    return (
        <Link as={DoczLink} {...props}>
            {props.children}
        </Link>
    )
}
